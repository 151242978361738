<template>
  <Breadcrumbs main="Integration Guide" title="Getting Started" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Getting Started</h5>
          </div>
          <div class="card-body">
            <p>
              For usage of the webapp and connecting it with our services, there have to be some configurations
              made.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Initialization</h5>
            <p>
              For the basic initialization of the webapp, the customer has to provide the <code>POI ID</code>, which
              usually comes by selecting a gas station on the map. Additionally, you can provide a <code>Partner</code>
              parameter to ensure that your users are tracked as your users.
            </p>
            <p>
              Example:
              <ul class="list-group">
                <li class="list-group-item">Test environment: <code>https://rydpay.thinxcloud-staging.de/?pid=&lt;POI_ID&gt;&amp;partner=&lt;PARTNER_NAME&gt;</code></li>
                <li class="list-group-item">Production environment: <code>https://rydpay.thinxcloud.de/?pid=&lt;POI_ID&gt;&amp;partner=&lt;PARTNER_NAME&gt;</code></li>
              </ul>
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>POI IDs</h5>
            <p>
              The <code>POI ID</code> (PID) is needed to determine the gas station of the customer. Without the PID, the user will
              be redirected to the ryd Map.
            </p>
            <p>
              Test POI IDs:
              <ul class="list-group">
                <li class="list-group-item"><strong>POI ID - Automatic (Prepay):</strong> 5f7c5d5d09838cf9cbfd3ecf</li>
                <li class="list-group-item"><strong>POI ID - Manual (Postpay):</strong> 5fa92c325bce72222dcde699</li>
              </ul>
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Partner IDs</h5>
            <p>
              The <code>Partner ID</code> is used to track the source from which the user is entering the webapp. This ID is not required for testing but can be passed as a URL parameter.
            </p>
            <p>
              Example: <code>?partner=yourCompanyName</code>
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Top-up Data Callback</h5>
            <p>
              You can receive a callback in your app after the user completes the payment flow using the <code>callback</code> parameter. This parameter must be a valid URL.
            </p>
            <p>
              Example of a callback: <code>https://www.partner-callback.com?paymentdata=%7B%22amount%22%3A22.22%2C%22price%22%3A1.345%2C%22total%22%3A29.89%2C%22stationId%22%3A%225f7c5d5d09838cf9cbfd3ecf%22%7D</code>
            </p>

          </div>
        </div>
        <!-- CARD END -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GettingStarted",
  keywords: ['webapp initialization', 'POI ID', 'Partner ID', 'Top-up Data Callback', 'ryd pay', 'Integration']
}
</script>