<template>
  <Breadcrumbs main="rydpay APIs" title="Account Settings"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute m-b-45">
          <div class="card-header bg-primary">
            <h5 class="text-white">General Information</h5>
          </div>
          <div class="card-body">
            <br/>
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Ryd provides two separate environments for
              partners:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Staging/Test Environment</b>: This environment is used for testing purposes, allowing partners to
                perform API integration tests before going live.
              </li>
              <li class="list-group-item">
                <b>Production Environment</b>: This environment is for live operations and should be used once the
                integration has been thoroughly tested.
              </li>
            </ul>

            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Each environment is fully isolated,
              meaning:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                The API tokens for the staging and production environments are separate. These tokens are not
                interchangeable between environments.
              </li>
              <li class="list-group-item">
                The rydpay API Gateway/server URLs are also different for each environment, reflecting the distinction between
                testing and live operations.
              </li>
            </ul>
          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card card-absolute m-b-45">
          <div class="card-header bg-success">
            <h5>Ryd's Staging/Test Environment</h5>
          </div>
          <div class="card-body" v-if="!isLoadingPartnerConfigs">
            <br/>
            <h5><i class="icofont icofont-hand-right font-success m-b-20">&nbsp;</i>Token</h5>


            <div v-if="tt4ApiTokensData && tt4ApiTokensData.tt4ApiTokenStaging" class="table-responsive product-table">
              <table class="table table-border table-striped display col-sm-12">
                <thead class="bg-success">
                <tr>
                  <th class="bg-success" scope="col" style="width: 15%;">Created At</th>
                  <th class="bg-success" scope="col" style="width: 10%;">Active</th>
                  <th class="bg-success" scope="col" style="width: 70%;">API Token</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="tokenItem in tt4ApiTokensData.tt4ApiTokenStaging" :key="tokenItem.id">
                  <!-- Column 1: Created At -->
                  <td>
                    {{ toLocalTimeString(tokenItem.createdAt) }} ({{ userTimezone }} Time)
                  </td>

                  <!-- Column 2: Active -->
                  <td>
                    <i v-if="tokenItem.active" class="fa fa-check-square fa-2x text-success"></i>
                    <i v-else class="fa fa-times-circle fa-2x text-danger"></i>
                  </td>

                  <!-- Column 3: Token and Actions -->
                  <td>
                    <div class="d-flex align-items-center">
                      <!-- Token with Blur Effect -->
                      <span :class="{ 'blur-token': !tokenItem.visible }">{{ tokenItem.token }}</span>

                      <!-- Action Buttons with spacing -->
                      <div>
                        <button class="btn btn-success m-l-30 px-4" @click="toggleVisibility(tokenItem)">
                          <i class="fa fa-eye px-2"></i> View
                        </button>
                        <button class="btn btn-success m-l-30 px-4" @click="copyToken(tokenItem.token, 'Token')">
                          <i class="fa fa-copy px-2"></i> Copy
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


            <div v-else>

              <b> Unavailable: API key not displayed. This could be due to:</b>

              <ul class="list-group m-t-10">
                <li class="list-group-item"><i class="fa fa-arrow-circle-right font-success"></i>
                  1. No key has been generated yet
                </li>
                <li class="list-group-item"><i class="fa fa-arrow-circle-right font-success"></i>
                  2. Access restrictions based on contractual agreements
                </li>
                <li class="list-group-item"><i class="fa fa-arrow-circle-right font-success"></i>
                  3. Visibility limitations in the ryd partner portal
                </li>
              </ul>

            </div>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>rydpay API Gateway/Server URL:</h5>
            <ul class="list-group alternating-list">
              <li class="list-group-item col-sm-10 col-md-8 col-lg-6 border border-primary">
                      <span>
                        https://tt4.thinxcloud-staging.de
                      </span>
                <button class="m-l-50 btn btn-success"
                        @click="copyToken('https://tt4.thinxcloud-staging.de', 'Gateway/Server URL')"><i
                    class="fa fa-copy"></i>Copy
                </button>
              </li>
            </ul>
            <br/>
          </div>

          <!-- Loading Indicator START -->
          <div v-if="isLoadingPartnerConfigs" class="loading-indicator">
            <br/>
            <h6 class="sub-title mb-0 text-center">Loading ... Your Account Settings</h6>
            <div class="loader-box">
              <div class="loader-17"></div>
            </div>
          </div>
          <!-- Loading Indicator END -->

        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card card-absolute m-b-45">
          <div class="card-header bg-primary">
            <h5>Ryd's Production Environment</h5>
          </div>
          <div class="card-body" v-if="!isLoadingPartnerConfigs">
            <br/>
            <h5><i class="icofont icofont-hand-right font-primary m-b-20">&nbsp;</i>Token</h5>


            <div v-if="tt4ApiTokensData && tt4ApiTokensData.tt4ApiTokenProd" class="table-responsive product-table">
              <table class="table table-border table-striped display col-sm-12">
                <thead class="bg-primary">
                <tr>
                  <th class="bg-primary" scope="col" style="width: 15%;">Created At</th>
                  <th class="bg-primary" scope="col" style="width: 10%;">Active</th>
                  <th class="bg-primary" scope="col" style="width: 70%;">API Token</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="tokenItem in tt4ApiTokensData.tt4ApiTokenProd" :key="tokenItem.id">
                  <!-- Column 1: Created At -->
                  <td>
                    {{ toLocalTimeString(tokenItem.createdAt) }} ({{ userTimezone }} Time)
                  </td>

                  <!-- Column 2: Active -->
                  <td>
                    <i v-if="tokenItem.active" class="fa fa-check-square fa-2x text-primary"></i>
                    <i v-else class="fa fa-times-circle fa-2x text-danger"></i>
                  </td>

                  <!-- Column 3: Token and Actions -->
                  <td>
                    <div class="d-flex align-items-center">
                      <!-- Token with Blur Effect -->
                      <span :class="{ 'blur-token': !tokenItem.visible }">{{ tokenItem.token }}</span>

                      <!-- Action Buttons with spacing -->
                      <div>
                        <button class="btn btn-primary m-l-30 px-4" @click="toggleVisibility(tokenItem)">
                          <i class="fa fa-eye px-2"></i> View
                        </button>
                        <button class="btn btn-primary m-l-30 px-4" @click="copyToken(tokenItem.token, 'Token')">
                          <i class="fa fa-copy px-2"></i> Copy
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


            <div v-else>
              <b> Unavailable: API key not displayed. This could be due to:</b>

              <ul class="list-group m-t-10">
                <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                  1. No key has been generated yet
                </li>
                <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                  2. Access restrictions based on contractual agreements
                </li>
                <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                  3. Visibility limitations in the ryd partner portal
                </li>
              </ul>

            </div>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>rydpay API Gateway/Server URL:</h5>
            <ul class="list-group alternating-list">
              <li class="list-group-item col-sm-10 col-md-8 col-lg-6 border border-primary">
                      <span>
                        https://tt4.thinxcloud.de
                      </span>
                <button class="m-l-50 btn btn-primary"
                        @click="copyToken('https://tt4.thinxcloud.de', 'Gateway/Server URL')"><i
                    class="fa fa-copy"></i>Copy
                </button>
              </li>
            </ul>
            <br/>
            <br/>
          </div>

          <!-- Loading Indicator START -->
          <div v-if="isLoadingPartnerConfigs" class="loading-indicator">
            <br/>
            <h6 class="sub-title mb-0 text-center">Loading ... Your Account Settings</h6>
            <div class="loader-box">
              <div class="loader-17"></div>
            </div>
          </div>
          <!-- Loading Indicator END -->

        </div>
        <!-- CARD END -->

        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5>Webhooks</h5>
          </div>
          <div class="card-body">
            <p>Available webhooks in rydpay APIs can be found at: </p><a class="font-success"
                                                                         href="/integration-guide/rydpay-apis/webhooks">rydpay
            API Webhooks</a>
          </div>
        </div>


        <!-- toast-container start -->
        <!-- Success Toast -->
        <div v-show="showSuccessToast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-body bg-info">
            Token copied to clipboard!
          </div>
        </div>

        <!-- Error Toast -->
        <div v-show="showErrorToast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-body bg-error">
            Failed to copy token.
          </div>
        </div>
        <!-- toast-container END -->

      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import {RPP_API_SERVER_URL} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {auth} from "@/firebase/firebaseInit";
import {toLocalTimeString} from "@/utils/dateUtils";


export default {
  name: 'AccountSettings',
  keywords: [
    'API Key', 'Token', 'Authentication', 'Account', 'Staging', 'Production', 'Server Url',
    'Access Key', 'Access Token', 'API Access', 'Generate Key', 'Retrieve Token', 'API Authentication Key',
    'Environment Keys', 'Environment Tokens', 'API Management', 'Key Management', 'Token Management',
    'Test Environment', 'Live Environment', 'Sandbox Environment', 'Production Access', 'API Credentials',
    'Security Key', 'Key Generation', 'Token Generation', 'API Authorization', 'API Security',
    'Generate API Key', 'Create API Key', 'API Key Creation', 'Key Retrieval', 'Token Retrieval',
    'API Key Setup', 'API Token Setup', 'Account Security', 'API Access Control', 'Key Storage',
    'Token Storage', 'API Integration', 'API Access Management', 'Credential Management', 'Environment Configuration',
    'Staging Key', 'Production Key', 'Staging Token', 'Production Token', 'Fetch API Key', 'Fetch API Token',
    'Download API Key', 'Download API Token', 'API Key Activation', 'API Token Activation', 'Service Credentials',
    'API Setup', 'Key Configuration', 'Token Configuration', 'Secure API Key', 'Secure API Token'
  ],
  data() {
    return {
      tt4ApiTokensData: {},
      isLoadingPartnerConfigs: false,
      showSuccessToast: false,
      showErrorToast: false,
      userTimezone: '',
    };
  },
  mounted() {
    this.userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.fetchPartnerConfigs();
  },
  methods: {
    toLocalTimeString,
    async fetchPartnerConfigs() {

      const {uid} = getUserAddDataFromLocalStorage();

      try {
        this.isLoadingPartnerConfigs = true;
        const response = await axios.get(`${RPP_API_SERVER_URL}/partner-configs?singleUserId=${uid}`, {
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY
          }
        });
        this.tt4ApiTokensData = response.data;
      } catch (error) {
        console.error('API fetch error:', error);
        this.tt4ApiTokensData.errorMessage = "NOT available.";
      } finally {
        this.isLoadingPartnerConfigs = false;
      }
    },
    toggleVisibility(tokenItem) {
      tokenItem.visible = !tokenItem.visible;
    },
    async copyToken(token, categoryText) {

      navigator.clipboard.writeText(token)
          .then(() => {
            this.$toast.show(categoryText + ' successfully copied to clipboard.', {
              theme: 'outline',
              position: 'bottom',
              type: 'success',
              duration: 5000
            });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ' + categoryText + '. Please try again.', {
              theme: 'outline',
              position: 'bottom',
              type: 'danger',
              duration: 8000
            });
          });
    },
    async fetchUsers() {

      this.isUsersLoading = true;

      const user = auth.currentUser;
      //console.log("userListItemUser", user);
      const uid = user ? user.uid : null;
      this.currentUserUid = uid;

      try {
        const response = await axios.get(`${RPP_API_SERVER_URL}`, {
          params: {
            page: this.currentPage,
            size: this.pageSize,
            searchKey: this.searchKey,
            sortBy: this.sortColumn,
            order: this.sortOrder,
          },
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY,
            'uid': uid
          }
        });

        this.users = response.data.users;
        this.totalUsers = response.data.total;
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle error
      } finally {
        this.isUsersLoading = false;
      }
    },
    showToast(toastId) {
      const toastEl = document.getElementById(toastId);
      const toast = new bootstrap.Toast(toastEl);
      toast.show();
    },
  }
};

</script>

<style lang="scss" scoped>

@import "@/assets/scss/utils/variables";
@import "@/assets/scss/components/datatable";
</style>

<style scoped>
@import "@/assets/css/productlist.css";


.blur-token {
  filter: blur(4px);
}
</style>