<template>
  <Breadcrumbs main="Integration Guide" title="Troubleshooting & Support" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Troubleshooting & Support</h5>
          </div>
          <div class="card-body">
            <p>
              In this section, we cover common issues you may encounter during the integration of the ryd pay webapp and how to troubleshoot them. If further assistance is needed, please reach out to our support team.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Issue: Redirected to the Map</h5>
            <p>
              If you are redirected to the map page when trying to access the ryd pay webapp, it is likely due to one of the following reasons:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                The <code>pid</code> or <code>mtskid</code> parameter is missing in the URL.
              </li>
              <li class="list-group-item">
                The current <code>pid</code> is offline.
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Issue: Payment Failed</h5>
            <p>
              Payment failures can occur for various reasons. If a payment fails, ensure that:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                The payment method is valid and correctly added to the account.
              </li>
              <li class="list-group-item">
                The payment system is not experiencing downtime (check with the ryd support team for live updates).
              </li>
              <li class="list-group-item">
                You are using the correct test credentials in the testing environment.
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Issue: Adding Credit Card Fails</h5>
            <p>
              If adding a credit card fails, verify that:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                The cardholder name matches the account username exactly.
              </li>
              <li class="list-group-item">
                The test credit card being used is valid (for example, the test MasterCard 5123-4500-0000-0008).
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Issue: Missing or Incorrect Invoice</h5>
            <p>
              If the invoice is missing or shows the wrong amount, this could be due to the test environment configuration. Please note that test accounts are configured with simulated data, such as predefined fuel amounts.
            </p>
            <p>
              In production, the invoice can be downloaded from the transaction history in the profile section.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Issue: Wrong Fuel Amount on the Success Screen</h5>
            <p>
              In the test environment, certain gas stations are set to simulate fueling amounts. For example, the "Automatic noop" station will always display a fueling amount of €80.87 for Super 95 and €100 for Diesel. In a live environment, the actual amount is retrieved from the connected cashier system.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Support Contact</h5>
            <p>
              If you encounter issues that cannot be resolved through troubleshooting, please contact our support team for further assistance:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <strong>Sales Manager:</strong> Marco Weber (marco.weber@ryd.one)
              </li>
              <li class="list-group-item">
                <strong>Product Owner:</strong> Yaroslav Tirakhov (yaroslav.tirakhov@ryd.one)
              </li>
            </ul>

          </div>
        </div>
        <!-- CARD END -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Troubleshooting",
  keywords: ['troubleshooting', 'support', 'issues', 'payment failed', 'redirect to map', 'invoice issues', 'credit card issues']
}
</script>