<template>
  <Breadcrumbs main="Integration Guide" title="Introduction" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Introduction</h5>
          </div>
          <div class="card-body">
            <p>
              ryd pay is a mobile fuel payment service which enables customers to use their smartphones to
              directly pay at the pump, which means that the user no longer has to go into the shop.
            </p>
            <p>
              The following documentation introduces the ryd pay web application (short: webapp) that allows
              third party applications to integrate the feature into their product.
            </p>
            <p>
              In general, the webapp can perform the following actions:
            </p>

            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Registration of a new user to the ryd pay service
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Guest user flow (pay without an account)
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Login/authentication of a user
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Managing address data
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Managing payment credentials
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Pay at pump
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Show a map of gas stations around the user that can be used with ryd pay
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Add loyalty programs
              </li>
            </ul>
          </div>
        </div>
        <!-- CARD END -->

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Introduction",
  keywords: ['ryd pay', 'mobile fuel payment', 'webapp integration', 'user registration', 'guest user flow', 'payment credentials']
}
</script>