<template>
  <Breadcrumbs main="Integration Guide" title="Guides & Tutorials" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Guides & Tutorials</h5>
          </div>
          <div class="card-body">
            <p>
              This section provides step-by-step guides and tutorials for integrating and using the ryd pay webapp and APIs. Each guide is designed to help developers and partners quickly get started and troubleshoot common issues.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Basic Webapp Integration</h5>
            <p>
              Learn how to integrate the ryd pay webapp into your existing system, either through an IFrame or WebView. This guide covers the setup process, customization options, and how to handle user sessions.
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                Step 1: Configure your POI ID and Partner ID as URL parameters.
              </li>
              <li class="list-group-item">
                Step 2: Implement the webapp into your application using an IFrame or WebView.
              </li>
              <li class="list-group-item">
                Step 3: Test the integration using provided test accounts and environments.
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Customizing the Webapp</h5>
            <p>
              Learn how to customize the appearance of the ryd pay webapp by adjusting the theme colors and branding. This guide provides information on which parameters to set to align the webapp with your brand’s visual identity.
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                Step 1: Provide the primary and secondary color values for the app's background and text.
              </li>
              <li class="list-group-item">
                Step 2: Adjust button and card styles according to your design specifications.
              </li>
              <li class="list-group-item">
                Step 3: Test the theming changes in the staging environment before deploying to production.
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>API Integration Guide</h5>
            <p>
              For partners looking to integrate the ryd pay APIs directly, this tutorial explains the key API endpoints and how to interact with them. It covers authentication, the top-up process, and error handling.
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                Step 1: Authenticate using the provided API keys and tokens.
              </li>
              <li class="list-group-item">
                Step 2: Call the top-up API to initiate a transaction.
              </li>
              <li class="list-group-item">
                Step 3: Handle errors and edge cases, such as failed transactions or timeouts.
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Testing and Troubleshooting</h5>
            <p>
              This guide helps you set up a testing environment to verify that your integration works as expected. It also provides troubleshooting steps for common issues during development.
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                Step 1: Set up test user accounts and use the provided test credit cards.
              </li>
              <li class="list-group-item">
                Step 2: Verify the payment flow using different user states (registered, guest, etc.).
              </li>
              <li class="list-group-item">
                Step 3: Troubleshoot common issues like failed payments or incorrect callbacks.
              </li>
            </ul>

          </div>
        </div>
        <!-- CARD END -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuidesTutorials",
  keywords: ['webapp integration', 'API integration', 'customization', 'testing', 'troubleshooting', 'guides', 'tutorials']
}
</script>