<template>
  <Breadcrumbs main="Integration Guide" title="Terms & Definitions" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Terms & Definitions</h5>
          </div>
          <div class="card-body">
            <p>
              Below are key terms and definitions related to the ryd pay webapp and its integration process.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>POI (Point of Interest)</h5>
            <p>
              A POI refers to a specific location, in this case, a gas station, where ryd pay can be used. Each POI is identified by a unique <code>POI ID</code>, which is used in the integration process to direct users to the correct gas station.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>PID (POI ID)</h5>
            <p>
              The <code>POI ID</code> (PID) is a unique identifier for each gas station that supports ryd pay. It is required for directing users to a specific gas station during the payment process.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Partner ID</h5>
            <p>
              The <code>Partner ID</code> is used to identify which third-party application or partner is directing users to the ryd pay webapp. It helps track partner-driven transactions and user flows.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Callback URL</h5>
            <p>
              The <code>Callback URL</code> is a parameter that allows the webapp to redirect users back to the partner’s system after a transaction or action has been completed. It is often used in combination with tracking parameters such as payment data.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>3D Secure</h5>
            <p>
              <code>3D Secure</code> is an additional security layer for online credit and debit card transactions. It helps reduce fraud by requiring the user to authenticate the transaction with their bank.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>WebView</h5>
            <p>
              A <code>WebView</code> is a browser-like component used within mobile apps to display web content. The ryd pay webapp can be embedded into native apps via a WebView for seamless integration.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Sandbox</h5>
            <p>
              The <code>Sandbox</code> environment is a testing environment where developers can simulate real-world scenarios without affecting the live production environment. All transactions and interactions in the sandbox are for testing purposes only.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Authentication</h5>
            <p>
              <code>Authentication</code> is the process of verifying the identity of a user. The ryd pay webapp supports multiple authentication methods such as email and password, Google, Apple, and Amazon logins.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>API (Application Programming Interface)</h5>
            <p>
              An <code>API</code> allows different software systems to communicate with each other. The ryd pay APIs allow partners to integrate features like payment processing, user management, and transaction tracking into their own systems.
            </p>

          </div>
        </div>
        <!-- CARD END -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsDefinitions",
  keywords: ['terms', 'definitions', 'POI', 'PID', 'Partner ID', 'Callback URL', '3D Secure', 'WebView', 'sandbox', 'authentication', 'API']
}
</script>