
// Returns the date part in 'YYYY-MM-DD' format from a date string in ISO format
export function parseDateStringYyyyMmDd(dateString) {
    return dateString.split('T')[0];
}

export function convertDateToISOString(date) {
    return new Date(date).toISOString();
}

export function parseDateStringYyyyMmDdNoBinder(dateString) {
    const yyyyMmDd = dateString.split('T')[0];
    return yyyyMmDd.replace(/-/g, ''); // Remove hyphens
}

export function convertDateTimeToReadFormat(datetimeStr) {
    // Split the string by the 'T' to separate date and time
    const [date, time] = datetimeStr.split('T');

    // Split the time by the ':' and take only the first two segments (hours and minutes)
    const [hours, minutes] = time.split(':');

    // Concatenate the date and time with a space and return it
    return `${date} ${hours}:${minutes}`;
}

export function toLocalTimeString(utcDateString) {
    const date = new Date(utcDateString);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,  // Enforce 24-hour format
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    const formatter = new Intl.DateTimeFormat('en-CA', options); // Using Canadian English to ensure YYYY-MM-DD format
    const parts = formatter.formatToParts(date);
    let year, month, day, hour, minute;

    parts.forEach(part => {
        if (part.type === 'year') {
            year = part.value;
        } else if (part.type === 'month') {
            month = part.value;
        } else if (part.type === 'day') {
            day = part.value;
        } else if (part.type === 'hour') {
            hour = part.value;
        } else if (part.type === 'minute') {
            minute = part.value;
        }
    });

    return `${year}-${month}-${day} ${hour}:${minute}`; // Constructing the date in the desired format
}