<template>
  <Breadcrumbs main="Accounting" title="Q8 Reconciliation Process"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP START -->
              <div class="input-group m-0 col-sm-12">
                <div class="row align-items-center m-r-30">

                  <div class="col text-center f-w-700">
                    File Name Search Item:
                  </div>
                </div>

                <input v-model="searchQuery" class="form-control form-control-plaintext ryd-search-input"
                       type="search" id="search-query"
                       placeholder="Enter a search term that is part of the file name ..">

                <button @click="handleSearch"
                        class="btn btn-pill btn-success btn-air-success active input-group-text m-r-20">
                  <i class="fa fa-search m-r-5"></i>Search
                </button>


              </div>
            </div>
            <!-- INPUT GROUP END -->
          </div>
        </div>
        <!-- CARD 1 END -->

        <!-- CARD 2 START -->
        <div class="card"
             v-if="!isLoadingInvoiceFilesResponse && invoiceResponse && invoiceResponse.files && invoiceResponse.files.length > 0">
          <div class="card-body">

            <table class="table table-bordernone table-striped display" id="basic-1">
              <thead class="bg-primary text-white">
              <tr>
                <th class="bg-primary" scope="col">File Name</th>
                <th class="bg-primary" scope="col">File Creation Date Time</th>
                <th class="bg-primary" scope="col">Download</th>
                <th class="bg-primary" scope="col">Uploaded To Q8 SFTP?</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="fileItem in invoiceResponse.files" :key="fileItem.name">
                <td>{{ fileItem.name }}</td>
                <td>{{ fileItem.timeCreated }}</td>
                <td>
                  <a href="#" @click.prevent="downloadFile(fileItem)">
                    Download
                  </a>
                </td>
                <td>{{ fileItem.metadata?.hasBeenUploadedToPartnersFtpServer === 'true' ? 'Yes' : 'NO' }}</td>
              </tr>
              </tbody>
            </table>

            <nav aria-label="User list pagination" class="m-t-15">
              <ul class="pagination">

                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="page-link" @click="prevPage" :disabled="currentPage === 1">Previous Page</button>
                </li>

                <li class="page-item" :class="{ disabled: !invoiceResponse.pageToken }">
                  <button class="page-link" @click="nextPage" :disabled="!invoiceResponse.pageToken">Next Page</button>
                </li>
              </ul>
            </nav>

          </div>
        </div>


        <div class="card" v-if="!isLoadingInvoiceFilesResponse && invoiceResponse && !invoiceResponse.files">
          <div class="card-body">
            <b>No File has been found with the given search item.</b>
          </div>
        </div>

        <div v-if="isLoadingInvoiceFilesResponse">
          <div class="card">
            <div class="card-body">
              <!-- Loading Indicator START -->
              <div class="loading-indicator">
                <h6 class="sub-title mb-0 text-center">Loading ... generated reconciliation files for Q8</h6>
                <div class="loader-box">
                  <div class="loader-17"></div>
                </div>
              </div>
              <!-- Loading Indicator END -->
            </div>
          </div>
        </div>


        <!-- card 3 start -->
        <div class="card card-absolute m-t-15">
          <div class="card-header bg-info">
            <h5 class="text-white">Important Notes</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">

              <li class="list-group-item">
                <i class="icofont icofont-hand-right font-primary"></i>
                <b class="font-primary f-w-900">File Status Explanation</b>
                <ul class="list-group m-t-15">
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    1. If a file has <code>Yes</code> in the column <code>Uploaded To Q8 SFTP?</code>, it indicates that
                    the file has been successfully uploaded to Q8's SFTP server <code>(kpsftp.q8.nl)</code> under the
                    folder <code>to/q8_retail-prod-rydpay</code>.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    2. If the column shows <code>No</code> , it means the file has been generated but not yet uploaded
                    to Q8's SFTP server. This is typically due to ongoing internal testing.
                  </li>
                </ul>
              </li>

              <li class="list-group-item">
                <i class="icofont icofont-hand-right font-primary"></i>
                <b class="font-primary f-w-900">XML Settlement File</b>
                <ul class="list-group m-t-15">
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    1. Each row in the table represents an XML settlement file that can be downloaded.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    2. The XML file contains detailed information about transactions for a specific day.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    3. The total amount of all transactions is stored in the <code>DebitAmount</code> attribute of the root SettlementFile element.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    4. In the example below, the total amount of all transactions is <code>262.46€</code>. (Click the thumbnail to enlarge the screenshot.)

                  <div class="container m-t-20 m-b-20">
                    <div class="row justify-content-center">
                      <div class="col-md-6">
                        <div class="row my-gallery gallery" id="aniimated-thumbnials-2" itemscope="" data-pswp-uid="1">
                          <figure class="col-md-4 col-6 img-hover hover-15" @click="showImg(0)" itemprop="associatedMedia" itemscope="">
                            <div class="thumbnail-container">
                              <img :src="require('../../../../src/assets/images/ryd/q8-settelement-file-example-01.png')" itemprop="thumbnail2" alt="Q8 xml settlement file example" class="img-fluid thumbnail">
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  </li>
                </ul>
              </li>

              <li class="list-group-item">
                <i class="icofont icofont-hand-right font-primary"></i>
                <b class="font-primary f-w-900">File Generation and Collection Schedule</b>
                <ul class="list-group m-t-15">
                  <li class="list-group-item"><i class="fa fa-clock-o font-primary"></i>
                    <b>Daily File Generation:</b> A new file is created each day, containing the previous day's transaction data.
                  </li>
                  <li class="list-group-item"><i class="fa fa-calendar font-primary"></i>
                    <b>Ryd File Generation Process:</b>
                    <ul>
                      <li>Frequency: Daily</li>
                      <li>Start Time: 9:00 AM (Berlin Time)</li>
                    </ul>
                  </li>
                  <li class="list-group-item"><i class="fa fa-calendar font-primary"></i>
                    <b>Q8 File Collection and Processing:</b>
                    <ul>
                      <li>Frequency: Hourly</li>
                      <li>Schedule: 24/7 at 30 minutes past each hour</li>
                    </ul>
                  </li>
                  <li class="list-group-item"><i class="fa fa-exclamation-triangle font-primary"></i>
                    <b>Troubleshooting:</b> If no new file appears by 9:15 AM (Berlin Time) on this page, possible causes include:
                    <ul>
                      <li>Delayed delivery of the report file to Google Cloud Storage by the ryd data team</li>
                      <li>Errors in the delivered CSV file</li>
                      <li>Issues with the invoicing process implementation</li>
                    </ul>
                  </li>
                </ul>
              </li>


              <li class="list-group-item">
                <i class="icofont icofont-hand-right font-primary"></i>
                <b class="font-primary f-w-900">Search Tips</b>
                <ul class="list-group m-t-15">
                  <li class="list-group-item"><i class="fa fa-calendar font-primary"></i>
                    Use the <code>YYYYMMDD</code> format (e.g., 20241008) to search for files by creation date.
                  </li>
                  <li class="list-group-item"><i class="fa fa-search font-primary"></i>
                    A search for <code>20241008</code> will return the settlement file created on that date, containing orders from the previous day: October 7, 2024.
                  </li>
                  <li class="list-group-item"><i class="fa fa-search font-primary"></i>
                    You can also search for partial file names or other identifying information.
                  </li>
                  <li class="list-group-item"><i class="fa fa-exclamation-circle font-primary"></i>
                    Remember that the file creation date is one day after the date of the transactions it contains.
                  </li>
                </ul>
              </li>

              <li class="list-group-item">
                <i class="icofont icofont-hand-right font-primary"></i>
                <b class="font-primary f-w-900">Troubleshooting Steps</b>
                <ol class="list-group m-t-15">
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    Check the Google Cloud Storage folder for the presence of the daily CSV file.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    Verify the format and content of the CSV file.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    Review the Google Cloud Function logs for any error messages.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    Confirm the SFTP server connection and permissions.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    Verify that files are being uploaded to the SFTP server correctly.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    If files are present on SFTP but not processed, contact Q8 support.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    For persistent issues, contact the ryd data team or the development team for further assistance.
                  </li>
                </ol>
              </li>



            </ul>
          </div>
        </div>
        <!-- card 3 end -->

        <!-- card 4 start -->
        <div class="card card-absolute m-t-15">
          <div class="card-header bg-info">
            <h5 class="text-white">Overview of Q8 Reconciliation Process</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-file-text font-primary"></i>
                <b>Daily Reconciliation Requirement:</b>
                <ul>
                  <li>Q8 requires a daily reconciliation file for transactions from the previous day.</li>
                  <li>Format: XML with Q8's predefined structure and attributes.</li>
                  <li>The XML file includes a <code>DebitAmount</code> attribute in the root <code>SettlementFile</code> element, representing the total transaction amount.</li>
                </ul>
              </li>
              <li class="list-group-item">
                <i class="fa fa-cloud-upload font-primary"></i>
                <b>Data Preparation:</b>
                <ul>
                  <li>The ryd data team exports the previous day's Q8-related orders daily.</li>
                  <li>This data is compiled into a CSV file.</li>
                  <li>The CSV file is uploaded to a designated folder on Google Cloud Storage.</li>
                </ul>
              </li>
              <li class="list-group-item">
                <i class="fa fa-cogs font-primary"></i>
                <b>Automated Processing:</b>
                <ul>
                  <li>A Google Cloud Function continuously monitors the designated folder.</li>
                  <li>Upon detecting a new CSV file, it triggers the reconciliation process.</li>
                  <li>The function generates the XML reconciliation file in the required format.</li>
                </ul>
              </li>
              <li class="list-group-item">
                <i class="fa fa-upload font-primary"></i>
                <b>File Delivery:</b>
                <ul>
                  <li>The generated XML file is uploaded to Q8's SFTP server.</li>
                  <li>Upload location: <code>kpsftp.q8.nl</code> under the folder <code>to/q8_retail-prod-rydpay</code>.</li>
                  <li>A predefined file naming convention is used.</li>
                </ul>
              </li>
              <li class="list-group-item">
                <i class="fa fa-clock-o font-primary"></i>
                <b>Processing Schedule:</b>
                <ul>
                  <li>Ryd file generation: Daily at 9:00 AM (Berlin Time)</li>
                  <li>Q8 file collection: Every hour, 24/7, at 30 minutes past each hour</li>
                </ul>
              </li>
              <li class="list-group-item">
                <i class="fa fa-book font-primary"></i>
                <b>Documentation:</b>
                Detailed process information is available <a target="_blank" href="https://thinxnet.atlassian.net/l/cp/UdRDbQpZ">on Confluence</a>.
              </li>
            </ul>
          </div>
        </div>
        <!-- card 4 end -->


      </div>
    </div>

  </div>
</template>

<script>
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios';
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";

export default {
  data() {
    return {
      selectedDirectory: 'partner-transfer/q8/outgoing',
      invoiceResponse: {
        files: null,
        pageToken: null,
        errorMessage: null
      },
      loggedInUserId: '',
      loggedInUserIsRyd: false,
      loggedInUserGroup: null,
      searchQuery: null,
      isLoadingInvoiceFilesResponse: false,
      currentPage: 1,
      pageTokens: [], // To keep track of pageTokens for previous pages
      pageSize: 10,
      totalPages: 0,
      lightBoxImages: [],
      visible: false,
      gallery1: [
        {
          image: 'q8-settelement-file-example-01.png'
        }
      ],
    };
  },
  mounted() {
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/ryd/' + item.image))
    });
  },
  created() {
    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.loggedInUserGroup = userGroup;
    this.fetchFiles();
  },
  methods: {
    showImg(index) {
      this.index = index;
      this.visible = true;
      const imageUrl = this.lightBoxImages[this.index];

      this.$swal({
        imageUrl,
        imageAlt: 'Image',
        showCloseButton: true,
        showConfirmButton: false,
        customClass: 'swal-wide',
        width: '80%'
      });
    },
    async fetchFiles(pageToken = null, nextPage = true, isNewSearch = false) {
      this.isLoadingInvoiceFilesResponse = true;
      try {
        const params = {
          pageSize: this.pageSize,
          searchQuery: this.searchQuery
        };

        if (isNewSearch) {
          // Reset pagination for new searches
          this.currentPage = 1;
          this.pageTokens = [];
          pageToken = null;
        } else if (nextPage) {
          params.pageToken = pageToken || (this.invoiceResponse ? this.invoiceResponse.pageToken : null);
        } else {
          if (this.currentPage > 1) {
            this.pageTokens.pop(); // Go back to the previous token
            params.pageToken = this.pageTokens[this.pageTokens.length - 1] || null;
          }
        }

        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/accounting/partner-exchanger/invoice-files`, {
          params: params,
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        this.invoiceResponse = await response.data;

        // Update pageTokens array
        if (nextPage && this.invoiceResponse && this.invoiceResponse.pageToken) {
          this.pageTokens.push(this.invoiceResponse.pageToken);
        }

      } catch (error) {
        this.invoiceResponse = {};
        this.invoiceResponse.errorMessage = error.response?.data?.error;
        console.error('Error fetching Invoice Files:', error);
      } finally {
        this.isLoadingInvoiceFilesResponse = false;
      }
    },

    handleSearch() {
      this.fetchFiles(null, true, true); // isNewSearch is true
    },

    async downloadFile(file) {
      const url = `${file.url}`;

      try {
        const response = await fetch(url, {
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });
        if (!response.ok) {
          this.$toast.show('Failed to copy the ', {theme: 'outline', position: 'top', type: 'danger', duration: 8000});
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = file.name.split('/').pop(); // Extracts file name from path
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        a.remove();
      } catch (error) {
        console.error('Download failed:', error);
      }
    },

    nextPage() {
      this.currentPage++;
      this.fetchFiles(null, true); // nextPage is true
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchFiles(null, false); // nextPage is false
      }
    },

  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/swal_custom.scss";
</style>