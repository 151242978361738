<template>
  <Breadcrumbs main="Integration Guide" title="Testing" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Testing</h5>
          </div>
          <div class="card-body">
            <p>
              This section provides details on how to test the ryd pay integration, including test user accounts, payment methods, and available testing environments.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Test User Accounts</h5>
            <p>
              We provide test user accounts for you to test the integration immediately. You can request additional test accounts if necessary. These accounts support the following login methods:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                Email address and password (pre-selection of country required)
              </li>
              <li class="list-group-item">
                Google login (pre-selection of country required)
              </li>
              <li class="list-group-item">
                Apple login (pre-selection of country required)
              </li>
              <li class="list-group-item">
                Amazon login (pre-selection of country required)
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Test Credit Cards</h5>
            <p>
              To test the ryd pay webapp, you need to add a test credit card to the test accounts. Use the following test credit card for development purposes:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <strong>MasterCard:</strong> 5123-4500-0000-0008 | CVC: 100 | Expiry Date: 01/2039
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Test PayPal Account</h5>
            <p>
              Instead of using a credit card or Google Pay, you can test with PayPal in the sandbox environment. Use the following test PayPal account:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <strong>Email:</strong> sb-wwirj372173@personal.example.com | <strong>Password:</strong> jC4VX9*7
              </li>
            </ul>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Google Pay Testing</h5>
            <p>
              If you prefer to test with Google Pay, you can use real credit card data in the test environment. The card will not be charged during the testing process.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Apple Pay Testing</h5>
            <p>
              You can also test with Apple Pay in the sandbox environment. More details will be provided when the Apple Pay sandbox testing becomes available.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Testing with MB Way</h5>
            <p>
              MB Way, popular in Portugal and Spain, can be used for testing as well. You need a valid mobile phone number registered in the MB Way portal.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Amazon Pay Testing</h5>
            <p>
              Amazon Pay is already available in the ryd pay webapp. When testing, you will be redirected to an Amazon page to log in or register.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Webview Implementation for Testing</h5>
            <p>
              To implement the ryd pay webapp into a native Android or iOS app, use the following resources:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <a href="https://developer.chrome.com/docs/android/custom-tabs/overview/" target="_blank">Android Custom Tabs Documentation</a>
              </li>
              <li class="list-group-item">
                <a href="https://developer.apple.com/documentation/safariservices/sfsafariviewcontroller" target="_blank">iOS Safari ViewController Documentation</a>
              </li>
              <li class="list-group-item">
                <a href="https://developer.apple.com/documentation/webkit/wkwebview" target="_blank">iOS WebView Documentation</a>
              </li>
            </ul>
            <p>
              A demo Android app for ryd pay web integration is available on our <a href="https://github.com/ThinxNetGmbH/ryd_pay_web_integration_android" target="_blank">GitHub Repository</a>.
            </p>

          </div>
        </div>
        <!-- CARD END -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Testing",
  keywords: ['testing', 'test accounts', 'test credit cards', 'PayPal sandbox', 'Google Pay', 'Apple Pay', 'Amazon Pay', 'webview implementation', 'testing environment']
}
</script>

<style scoped>
.card-absolute {
  margin-top: 20px;
}

.card-header {
  padding: 10px;
}

.card-body {
  padding: 15px;
}

h5 {
  font-size: 20px;
  margin-bottom: 15px;
}

p {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 1.6;
}

ul {
  margin-top: 15px;
}

li {
  font-size: 16px;
  margin-bottom: 5px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
