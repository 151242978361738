<template>
  <Breadcrumbs main="Integration Guide" title="FAQs" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Frequently Asked Questions (FAQs)</h5>
          </div>
          <div class="card-body">
            <p>
              Here are answers to some of the most commonly asked questions regarding the integration and use of the ryd pay webapp.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>How do I integrate the ryd pay webapp into my mobile app?</h5>
            <p>
              The ryd pay webapp can be integrated into your mobile app via an IFrame or WebView. Use the provided <code>POI ID</code> and <code>Partner ID</code> in the URL for proper tracking and redirection.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>What is the difference between the test and production environments?</h5>
            <p>
              The <code>test environment</code> allows developers to simulate transactions and other interactions without affecting live users or real payments. The <code>production environment</code> is the live system where actual transactions take place.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>How can I test payments without using real money?</h5>
            <p>
              Use the provided test credit card details in the sandbox environment to simulate payments. You can also test using PayPal, Google Pay, and Apple Pay in the sandbox.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>What do I do if the ryd pay webapp redirects to the map?</h5>
            <p>
              If the webapp redirects to the map, ensure that the <code>pid</code> or <code>mtskid</code> parameter is correctly set in the URL. The user may also be redirected if the specified <code>pid</code> is offline.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>How do I handle failed payments during testing?</h5>
            <p>
              If a payment fails during testing, verify that you are using the correct test credentials and check if the payment method is valid. Ensure that you are in the correct test environment and that there are no downtime issues with the payment provider.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Can I customize the appearance of the ryd pay webapp?</h5>
            <p>
              Yes, the ryd pay webapp can be customized with your brand's colors. Provide the required branding information, such as primary and secondary colors, button styles, and text colors, for integration.
            </p>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>What is the process for getting support?</h5>
            <p>
              If you need help with the integration or encounter issues, contact our support team:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                <strong>Sales Manager:</strong> Marco Weber (marco.weber@ryd.one)
              </li>
              <li class="list-group-item">
                <strong>Product Owner:</strong> Yaroslav Tirakhov (yaroslav.tirakhov@ryd.one)
              </li>
            </ul>

          </div>
        </div>
        <!-- CARD END -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQs",
  keywords: ['FAQs', 'frequently asked questions', 'webapp integration', 'test environment', 'payment testing', 'ryd pay', 'support']
}
</script>