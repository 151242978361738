<template>
    <Breadcrumbs main="Integration Guide" title="Changelog" />
  
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
  
          <!-- CARD START -->
          <div class="card card-absolute">
            <div class="card-header bg-primary">
              <h5 class="text-white">Changelog</h5>
            </div>
            <div class="card-body">
              <p>
                Below is the changelog for the ryd pay webapp, detailing the updates and improvements made in each version.
              </p>
  
              <div class="table-responsive product-table">
                <table class="table table-bordered table-striped">
                  <thead class="bg-primary text-white">
                    <tr>
                      <th class="bg-primary">Version</th>
                      <th class="bg-primary">Date</th>
                      <th class="bg-primary">Author</th>
                      <th class="bg-primary">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>6.4</td>
                      <td>31/01/2024</td>
                      <td>Daniela Buzziol</td>
                      <td>Wash top-up, Refer a friend</td>
                    </tr>
                    <tr>
                      <td>6.3</td>
                      <td>09/08/2023</td>
                      <td>Daniela Buzziol</td>
                      <td>Download order invoice</td>
                    </tr>
                    <tr>
                      <td>6.2</td>
                      <td>31/07/2023</td>
                      <td>Daniela Buzziol</td>
                      <td>CSV Export for history - Cost overview</td>
                    </tr>
                    <tr>
                      <td>6.1</td>
                      <td>30/06/2023</td>
                      <td>Daniela Buzziol</td>
                      <td>New history - Amazon SSO</td>
                    </tr>
                    <tr>
                      <td>6.0</td>
                      <td>09/05/2023</td>
                      <td>Daniela Buzziol</td>
                      <td>Vouchers - Loyalty - Amazon Pay</td>
                    </tr>
                    <tr>
                      <td>5.8</td>
                      <td>07/02/2023</td>
                      <td>Daniela Buzziol</td>
                      <td>Guest User - New UI</td>
                    </tr>
                    <tr>
                      <td>5.7</td>
                      <td>28/11/2022</td>
                      <td>Daniela Buzziol</td>
                      <td>Opt-in for partner marketing communication (double opt-in)</td>
                    </tr>
                    <!-- Add more changelog entries as needed -->
                  </tbody>
                </table>
              </div>
  
            </div>
          </div>
          <!-- CARD END -->
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Changelog",
    keywords: ['changelog', 'version history', 'updates', 'ryd pay', 'integration updates']
  }
  </script>  