<template>
  <!-- Full Page Loading Overlay -->
  <div v-if="isLoading">
    <h6 class="sub-title mb-0 text-center">Perform some checks and prepare some data to create a new user ...</h6>
    <div class="loader-box">
      <div class="loader-19"></div>
    </div>
  </div>

  <div class="col-sm-12" v-if="!isLoading">
    <div class="card">
      <div class="card-header bg-primary">
        <h5>Create and Invite a User</h5>
      </div>
      <div class="card-body">
        <div class="form theme-form">

            <div class="row border-bottom m-b-20">
              <div class="col">
                <div class="mb-3">
                  <label for="firstName" class="fw-bold">FirstName *</label>
                  <input class="form-control border border-primary"
                         type="text"
                         id="firstName"
                         ref="firstName"
                         v-model="newUser.firstName"
                         :class="{ 'is-invalid': firstNameError }"
                         @input="validateFirstName"
                         placeholder="The new user's firstname">
                </div>
              </div>

              <!-- INPUT GROUP 1 ERROR START -->
              <div v-if="firstNameError">
                <div class="row g-3 align-items-center m-b-20">
                  <div class="invalid-feedback" v-if="firstNameError">
                    Please enter a valid first name with at least one letter. Special characters are allowed.
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 1 ERROR END -->

            </div>



            <div class="row border-bottom m-b-20 m-t-20">
              <div class="col">
                <div class="mb-3">
                  <label for="lastName" class="fw-bold">Lastname *</label>
                  <input class="form-control border border-primary"
                         type="text"
                         id="lastName"
                         ref="lastName"
                         v-model="newUser.lastName"
                         :class="{ 'is-invalid': lastNameError }"
                         @input="validateLastName"
                         placeholder="The new user's lastname">
                </div>
              </div>

              <!-- INPUT GROUP 2 ERROR START -->
              <div v-if="lastNameError">
                <div class="row g-3 align-items-center m-b-20">
                  <div class="invalid-feedback" v-if="lastNameError">
                    Please enter a valid last name with at least one letter. Special characters are allowed.
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 2 ERROR END -->
            </div>



            <div class="row border-bottom m-b-20 m-t-20">
              <div class="col">
                <div class="mb-3">
                  <label for="email" class="fw-bold">Email *</label>
                  <input class="form-control border border-primary"
                         type="text"
                         id="email"
                         ref="email"
                         v-model="newUser.email"
                         :class="{ 'is-invalid': emailError }"
                         @input="validateEmail"
                         placeholder="The new user's email address that is used for sign in">
                </div>
              </div>

              <!-- INPUT GROUP 3 ERROR START -->
              <div v-if="emailError">
                <div class="row g-3 align-items-center m-b-20 m-l-15">
                  <div class="invalid-feedback" v-if="emailError">
                    Please enter a valid email. Ryd supports special characters like hyphens (-), underscores (_), and plus signs (+). However,
                    certain special letters from languages such as German, French, and Greek (e.g., ü, é, or θ) are not supported to ensure proper email communication.
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 3 ERROR END -->
            </div>



            <div class="row border-bottom m-b-20 m-t-20" v-if="newUser.loggedInUserGroup === 'ryd'">
              <div class="col">
                <div class="mb-6 m-r-30">
                  <label for="userGroup" class="fw-bold">User Group *</label>
                  <multiselect
                      class="form-control"
                      :class="{
                                'border-primary': !userGroupError,
                                'border-danger': userGroupError,
                                'is-invalid': userGroupError
                              }"
                      v-model="newUser.userGroup"
                      ref="userGroup"
                      tag-placeholder="Add this as new tag"
                      placeholder="Select a user group or partner. Auto-complete search is available."
                      label="name"
                      track-by="value"
                      :options="partnerNames"
                      :multiple="false"
                      :taggable="true"
                      @select="validateSelectedUserGroup"
                      @tag="addTag">
                  </multiselect>
                </div>
              </div>


              <!-- INPUT GROUP 4 ERROR START -->
              <div v-if="userGroupError">
                <div class="row g-6 align-items-center m-b-20 m-l-15">
                  <div v-if="userGroupError" class="invalid-feedback d-block">
                    Please select a user group or partner for the new user. Each group or partner has access only to specific predefined functionalities, as well as their own orders and statistics.
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 4 ERROR END -->
            </div>



            <div class="row row-checkboxes border-bottom m-b-20 m-t-20"
                 :class="{ 'is-invalid': rolesError }">
              <div class="col">
                <div class="mb-3">
                  <label for="roles" class="fw-bold">Roles *</label>
                  <div v-for="(role, index) in availableRoles" :key="index">
                    <div class="checkbox checkbox-primary">
                      <input :id="`checkbox-primary-${index}`"
                             type="checkbox"
                             :value="role.name"
                             ref="roles"
                             :checked="isRoleSelected(role.name)"
                             @change="toggleRoleSelection(role.name)"
                             :disabled="role.name === 'userManagementUser'">
                      <label :for="`checkbox-primary-${index}`" class="fw-bold">{{ role.name }}</label>
                      <p class="checkbox-item-description">{{ role.description }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- INPUT GROUP 4 ERROR START -->
              <div v-if="rolesError">
                <div class="row g-3 align-items-center m-b-20 m-l-15">
                  <div class="invalid-feedback" v-if="rolesError">
                    Please select at least one role
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 4 ERROR END -->
            </div>




            <div class="row ">
              <div class="col">
                <button class="btn btn-pill btn-success btn-air-success active btn-lg"
                        type="submit"
                        @click="createUser"
                        title="Create a new user">
                  <i class="fa fa-send m-r-15"></i>Create
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>


  <div class="col-sm-12 col-md-12">
    <div class="card card-absolute">
      <div class="card-header bg-info">
        <h5 class="text-white">Important Note</h5>
      </div>
      <div class="card-body">

        <ul class="list-group">
          <li class="list-group-item">
            <i class="icofont icofont-hand-right font-primary">&nbsp;</i>After the new user's data has been successfully
            validated and their account created, an email will be sent to the email address provided during
            registration.
          </li>
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>This email will
            contain instructions enabling the user to set up a password, thereby completing the
            registration process.
          </li>
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>The password set link
            provided in the email will expire after 24 hours. However,
            you have the option to re-initiate the password reset process for an existing account as many times as
            needed by visiting the <a href="/users/list">users list</a> page.
          </li>
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>If the new user does
            not find the email in their inbox, please advise them to check their spam or junk email folder.
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import {auth, db} from '@/firebase/firebaseInit';
import {doc, getDoc} from 'firebase/firestore';
import axios from 'axios';
import {sendPasswordResetEmail} from 'firebase/auth';
import {ROLE_DESCRIPTIONS, RPP_API_SERVER_URL, RPP_USERS_FIRESTORE_COLLECTION_NAME} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {fetchPartnerNames} from "@/utils/partnerNamesUtils";


export default {
  name: 'CreateUser',
  data() {
    return {
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        userGroup: null,
        roles: [],
        availableRoles: [],
        loggedInUserGroup: ''
      },
      error: null, // To handle and show any error messages
      isLoading: false,
      creatorId: null,
      userGroupOptions: {},
      availableRoles: [],
      selectedRoles: ['userManagementUser'], // Initialize with userManagementUser
      partnerNames: [],
      isUserGroupRyd: false,
      firstNameError: false,
      lastNameError: false,
      emailError:false,
      userGroupError: false,
      rolesError: false
    };
  },
  mounted() {
    this.initializeForm();
  },
  watch: {
    loggedInUserGroup(newVal) {
      this.newUser.loggedInUserGroup = newVal;
    },
    //availableRoles(newVal) {
    //  this.newUser.roles = [...newVal];
   // }
  },
  methods: {
    validateFirstName() {
      console.log("validate first name", this.newUser.firstName);
      const trimmedFirstName = this.newUser.firstName ? this.newUser.firstName.trim() : '';
      this.firstNameError = !trimmedFirstName;
      this.newUser.firstName = trimmedFirstName;
    },
    validateLastName() {
      const trimmedLastName = this.newUser.lastName ? this.newUser.lastName.trim() : '';
      this.lastNameError = !trimmedLastName;
      this.newUser.lastName = trimmedLastName;
    },
    validateEmail() {
      const trimmedEmail = this.newUser.email ? this.newUser.email.trim() : '';

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/u;

      // Validate if the trimmed email is not empty and matches the updated email pattern
      this.emailError = !trimmedEmail || !emailPattern.test(trimmedEmail);

      // Update the email in the model with the trimmed value
      this.newUser.email = trimmedEmail;
    },
    validateSelectedUserGroup() {

      if (this.newUser.loggedInUserGroup !== 'ryd') {
        // For non-ryd users, userGroup is always valid
        this.userGroupError = false;
        return;
      }

      // For ryd users, perform the existing validation
      if (!this.newUser.userGroup || typeof this.newUser.userGroup === 'string') {
        this.userGroupError = true;
      } else {
        this.userGroupError = false;
        this.newUser.userGroup = {
          name: this.newUser.userGroup.name,
          value: this.newUser.userGroup.value
        };
      }

    },
    isRoleSelected(roleName) {
      return this.newUser.roles.includes(roleName);
    },
    validateSelectedRoles() {
      console.log("111", this.newUser.roles);
      this.rolesError = this.newUser.roles.length < 1;
    },
    addTag(newTag) {
      this.partnerNames.push({ name: newTag, value: newTag });
      this.newUser.userGroup = { name: newTag, value: newTag };
      this.validateSelectedUserGroup();
    },
    createUser() {
      // Clear previous errors
      this.error = null;

      // Perform validations
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validateSelectedUserGroup();
      this.validateSelectedRoles();

      console.log("dddd", this.newUser.roles.length);

      // Check if there are any errors
      const errors = [];
      if (this.firstNameError) {
        errors.push('firstName');
      }
      if (this.lastNameError) {
        errors.push('lastName');
      }
      if (this.emailError) {
        errors.push('email');
      }
      if (this.userGroupError) {
        errors.push('userGroup');
      }
      if (this.rolesError) {
        errors.push('roles');
      }

      console.log("errors", errors);


      // If there are errors, scroll into view and focus on the first error
      if (errors.length > 0) {
        this.$nextTick(() => {
          const firstError = errors[0];
          const refName = `${firstError}`;
          // Check if the ref exists and has a $el property or is an element
          const refElement = this.$refs[refName]?.$el || this.$refs[refName];

          // Ensure refElement is a valid DOM element before performing actions
          if (refElement && refElement.scrollIntoView) {
            refElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            refElement.focus();
          }
        });
        return;
      }

      const userGroupValue = this.newUser.userGroup ? this.newUser.userGroup.value : this.newUser.loggedInUserGroup;

      // Retrieve the API key from environment variables
      const apiKey = process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY;

      // Request to your backend to create the user
      axios.post(RPP_API_SERVER_URL, {
        email: this.newUser.email,
        //password: this.generateRandomPassword,
        firstName: this.newUser.firstName,
        lastName: this.newUser.lastName,
        userGroup: userGroupValue,
        roles: this.selectedRoles,
        creatorId: this.creatorId,
        // other data...
      }, {
        headers: {
          'x-txn-auth-token': apiKey
        }
      })
          .then(() => {
            //sendPasswordResetEmail(auth, this.newUser.email);
            this.$router.push('/users/list'); // Redirect after successful creation
          })
          .catch((error) => {
            // Handle errors
            //console.error("333", error);

            // Initialize a default error message
            let errorMessage = 'An error occurred during user creation.';

            // Check if the error response exists and has the expected format
            if (error.response && error.response.data && error.response.data.errorDetails) {
              // Extract the error message from the response
              errorMessage = error.response.data.errorDetails || errorMessage;
            } else if (error.response && error.response.data && error.response.data.errorMessage) {
              // Extract the error message from the response
              errorMessage = error.response.data.errorMessage || errorMessage;
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error Message:', error.message);
              errorMessage = error.message;
            }

            // Update the local state with the extracted error message
            this.error = errorMessage;
          });
    },
    initializeForm() {
      this.isLoading = true;

      const { uid, isUserGroupRyd } = getUserAddDataFromLocalStorage();
      this.creatorId = uid;
      this.isUserGroupRyd = isUserGroupRyd;

      const userDocRef = doc(db, RPP_USERS_FIRESTORE_COLLECTION_NAME, uid);

      getDoc(userDocRef)
          .then(userDocSnap => {
            if (userDocSnap.exists()) {
              const userDoc = userDocSnap.data();
              //console.log("userDoc7777", userDoc);

              this.newUser.loggedInUserGroup = userDoc.userGroup;
              //this.newUser.userGroup = userDoc.userGroup;

              // Set the userGroup for non-ryd users
              if (userDoc.userGroup !== 'ryd') {
                this.newUser.userGroup = {
                  name: userDoc.userGroup,
                  value: userDoc.userGroup
                };
              }

              // Ensure userManagementUser is always included
              let roles = userDoc.roles;
              if (!roles.includes('userManagementUser')) {
                roles.push('userManagementUser');
              }

              this.availableRoles = roles
                  .filter(roleName => ROLE_DESCRIPTIONS[roleName])
                  .map(roleName => {
                    return {
                      name: roleName,
                      description: ROLE_DESCRIPTIONS[roleName]
                    };
                  });

              // Set userManagementUser as selected by default
              this.newUser.roles = ['userManagementUser'];
              this.selectedRoles = ['userManagementUser'];

              if(isUserGroupRyd){
                this.loadPartnerNames();
              }


            } else {
              console.log("No such document!");
            }
          })
          .catch(error => {
            console.error("Error getting document:", error);
          })
          .finally(() => {
            this.isLoading = false; // Stop loading after data is fetched
          });

    },
    generateRandomPassword(length = 8) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
      let password = '';
      for (let i = 0; i < length; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return password;
    },
    validateRoles() {
      // userManagementUser is always selected, so we don't need to check for empty selection
      this.rolesError = false;
    },
    toggleRoleSelection(roleName) {

      if (roleName === 'userManagementUser') {
        // Prevent deselection of userManagementUser
        return;
      }

      console.log("toggleRoleSelection");
      const index = this.selectedRoles.indexOf(roleName);
      console.log("toggleRoleSelection index", index);

      if (index > -1) {
        // Role is already selected, remove it
        this.selectedRoles.splice(index, 1);
      } else {
        // Add role to selectedRoles
        this.selectedRoles.push(roleName);
      }

      // Update newUser.roles to match selectedRoles
      this.newUser.roles = [...this.selectedRoles];

      // Call the validation method every time a checkbox changes
      this.validateRoles();
    },
    async loadPartnerNames() {
      try {
        const partnerNamesArray = await fetchPartnerNames(this.creatorId);
        this.partnerNames = partnerNamesArray.map(name => ({
          value: name,
          name: name
        }));
      } catch (error) {
        console.log("error", error);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/components/checkbox';
</style>