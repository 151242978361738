<template>
  <Breadcrumbs main="Guides: Car Wash" title="Car Wash Simulated Stations"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Purpose</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">Ryd provides virtual car wash stations in a STAGING environment, specifically designed for testing purposes.</li>
              <li class="list-group-item">These simulated car wash stations are intended to replicate various scenarios for API integration and testing</li>
            </ul>
          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card">
          <div class="card-header bg-success">
            <h5 class="text-white">Car Wash Stations</h5>
          </div>
          <div class="card-body">
            <br>
            <div class="table-responsive product-table">
              <table class="table table-bordered border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-success f-w-800 border border-dark">PLACE ID</th>
                  <th class="font-success f-w-800 border border-dark">POI ID</th>
                  <th class="font-success f-w-800 border border-dark">Station Type</th>
                  <th class="font-success f-w-800 border border-dark">POI Brand</th>
                  <th class="font-success f-w-800 border border-dark">Country</th>
                  <th class="font-success f-w-800 border border-dark">Currency</th>
                  <th class="font-success f-w-800 border border-dark">Tax Rate</th>
                  <th class="font-success f-w-800 border border-dark">Comments</th>
                </tr>
                </thead>
                <tbody>
                <!-- WEAT -->
                <tr>
                  <td class="border border-dark" rowspan="2">fddcf2ef-0a9d-4613-a943-7d592b4689c6</td>
                  <td class="border border-dark">fddcf2ef-0a9d-4613-a943-7d592b4689c6</td>
                  <td class="border border-dark">Gas</td>
                  <td class="border border-dark">Weat Pre-PostPay 0900911002</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">-</td>
                </tr>
                <tr>
                  <td class="border border-dark">1ac318ac-5e76-478b-ae11-eb985c743066</td>
                  <td class="border border-dark">Wash</td>
                  <td class="border border-dark">Weat CARWASH 0900911002</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">-</td>
                </tr>
                <!-- Wash Noop -->
                <tr>
                  <td class="border border-dark">637cc8c50d6212c7886f5afc</td>
                  <td class="border border-dark">637cc8c50d6212c7886f5afc</td>
                  <td class="border border-dark">Wash</td>
                  <td class="border border-dark">Noop Car Wash</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">-</td>
                </tr>
                <!-- Wash&Gas Automatic Noop 01 -->
                <tr>
                  <td class="border border-dark" rowspan="2">173adb65-36f5-4e79-a786-a2a894949ea0</td>
                  <td class="border border-dark">173adb65-36f5-4e79-a786-a2a894949ea0</td>
                  <td class="border border-dark">Gas</td>
                  <td class="border border-dark">DE Gas Automatic Noop 01</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">-</td>
                </tr>
                <tr>
                  <td class="border border-dark">72b9432c-e7f1-44f5-91ef-f67593c0e583</td>
                  <td class="border border-dark">Wash</td>
                  <td class="border border-dark">DE Wash Noop 01</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">-</td>
                </tr>
                <!-- Wash&Gas Manual Noop 02 -->
                <tr>
                  <td class="border border-dark" rowspan="2">9f085abd-e404-4115-8a70-98f2eefc61e0</td>
                  <td class="border border-dark">9f085abd-e404-4115-8a70-98f2eefc61e0</td>
                  <td class="border border-dark">Gas</td>
                  <td class="border border-dark">DE Gas Manual Noop 02</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">-</td>
                </tr>
                <tr>
                  <td class="border border-dark">3bb31453-6d66-4772-8adc-51a7ab0b655f</td>
                  <td class="border border-dark">Wash</td>
                  <td class="border border-dark">DE Wash Noop 02</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">-</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="text-white">Car Wash Configurations</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Noop Stations</b>: Each wash program is uniquely configured to test different scenarios like successful or rejected transactions and different expiration dates for the purchased tickets.</li>
            </ul>

            <br/>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped  border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-primary f-w-800 border border-dark">Pump</th>
                  <th class="font-primary f-w-800 border border-dark">Name</th>
                  <th class="font-primary f-w-800 border border-dark">Description</th>
                  <th class="font-primary f-w-800 border border-dark">ID</th>
                  <th class="font-primary f-w-800 border border-dark">OTC</th>
                  <th class="font-primary f-w-800 border border-dark">Start Date</th>
                  <th class="font-primary f-w-800 border border-dark">Validity</th>
                  <th class="font-primary f-w-800 border border-dark">isRedeemed</th>
                  <th class="font-primary f-w-800 border border-dark">Currency</th>
                  <th class="font-primary f-w-800 border border-dark">Price</th>
                  <th class="font-primary f-w-800 border border-dark">Comments</th>
                </tr>
                </thead>
                <tbody>
                <!-- Nass Pflege -->
                <tr>
                  <td class="border border-dark">1</td>
                  <td class="border border-dark">Nass Pflege</td>
                  <td class="border border-dark">
                    <ul>
                      <li>Aktivschaum</li>
                      <li>Waschen</li>
                      <li>Trocknen</li>
                    </ul>
                  </td>
                  <td class="border border-dark">10000014</td>
                  <td class="border border-dark">80000001</td>
                  <td class="border border-dark">Date of purchase</td>
                  <td class="border border-dark">1 Year</td>
                  <td class="border border-dark">Field Missing</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">5.99</td>
                  <td class="border border-dark"></td>
                </tr>
                <!-- Standard Pflege -->
                <tr>
                  <td class="border border-dark">2</td>
                  <td class="border border-dark">Standard Pflege</td>
                  <td class="border border-dark">
                    <ul>
                      <li>Aktivschaum</li>
                      <li>HD Wäsche</li>
                      <li>Felgen Rg</li>
                      <li>Trocknen</li>
                    </ul>
                  </td>
                  <td class="border border-dark">10000015</td>
                  <td class="border border-dark">80000002</td>
                  <td class="border border-dark">Date of purchase</td>
                  <td class="border border-dark">1 Day</td>
                  <td class="border border-dark">Field Missing</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">9.00</td>
                  <td class="border border-dark"></td>
                </tr>
                <!-- Komfort Pflege -->
                <tr>
                  <td class="border border-dark">3</td>
                  <td class="border border-dark">Komfort Pflege</td>
                  <td class="border border-dark">
                    <ul>
                      <li>Chemische Vorwäsche</li>
                      <li>Aktivschaum</li>
                      <li>HD Wäsche</li>
                      <li>Felgen Reinigung</li>
                      <li>Trocknen</li>
                    </ul>
                  </td>
                  <td class="border border-dark">10000016</td>
                  <td class="border border-dark">600001</td>
                  <td class="border border-dark">Date of purchase</td>
                  <td class="border border-dark">1 Year</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">11.99</td>
                  <td class="border border-dark"></td>
                </tr>
                <!-- Glanz Pflege -->
                <tr>
                  <td class="border border-dark">4</td>
                  <td class="border border-dark">Glanz Pflege</td>
                  <td class="border border-dark">
                    <ul>
                      <li>Chemische Vorwäsche</li>
                      <li>Aktivschaum</li>
                      <li>HD Wäsche</li>
                      <li>Felgen Rg / Wachs</li>
                      <li>Trocknen</li>
                    </ul>
                  </td>
                  <td class="border border-dark">10000017</td>
                  <td class="border border-dark">600002</td>
                  <td class="border border-dark">Date of purchase</td>
                  <td class="border border-dark">1 Day</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">12.00</td>
                  <td class="border border-dark"></td>
                </tr>
                <!-- Premium Pflege -->
                <tr>
                  <td class="border border-dark">5</td>
                  <td class="border border-dark">Premium Pflege</td>
                  <td class="border border-dark">
                    <ul>
                      <li>Chemische Vorwäsche</li>
                      <li>Aktivschaum</li>
                      <li>HD Wäsche</li>
                      <li>FG Rg / Wachspolitur / Klarspülung</li>
                      <li>Trocknen</li>
                    </ul>
                  </td>
                  <td class="border border-dark">10000018</td>
                  <td class="border border-dark">600003</td>
                  <td class="border border-dark">Date of purchase</td>
                  <td class="border border-dark">1 Year</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">15.99</td>
                  <td class="border border-dark">Order is always rejected</td>
                </tr>
                </tbody>
              </table>

            </div>
            <br/>

            <ul class="list-group">
              <li class="list-group-item"><b>WEAT</b></li>
            </ul>

            <br/>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped">
                <thead class="text-primary">
                <tr>
                  <th class="text-primary f-w-800 border border-dark">Pump</th>
                  <th class="text-primary f-w-800 border border-dark">Name</th>
                  <th class="text-primary f-w-800 border border-dark">Description</th>
                  <th class="text-primary f-w-800 border border-dark">ID</th>
                  <th class="text-primary f-w-800 border border-dark">OTC</th>
                  <th class="text-primary f-w-800 border border-dark">Start Date</th>
                  <th class="text-primary f-w-800 border border-dark">Validity</th>
                  <th class="text-primary f-w-800 border border-dark">isRedeemed</th>
                  <th class="text-primary f-w-800 border border-dark">Currency</th>
                  <th class="text-primary f-w-800 border border-dark">Price</th>
                  <th class="text-primary f-w-800 border border-dark">Comments</th>
                </tr>
                </thead>
                <tbody>
                <!-- Autowaesche Pkw Praemium-->
                <tr>
                  <td class="border border-dark">1</td>
                  <td class="border border-dark">Autowaesche Pkw Praemium</td>
                  <td class="border border-dark">
                    <ul>
                      <li>Chemische Vorwäsche</li>
                      <li>Aktivschaum</li>
                      <li>HD Wäsche</li>
                      <li>FG Rg / Wachspolitur / Klarspülung</li>
                      <li>Trocknen</li>
                    </ul>
                  </td>
                  <td class="border border-dark">10000019</td>
                  <td class="border border-dark">variable</td>
                  <td class="border border-dark">Date of purchase</td>
                  <td class="border border-dark">13 Weeks</td>
                  <td class="border border-dark">false</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">9.5</td>
                  <td class="border border-dark"></td>
                </tr>

                <!-- Autowaesche PKW einfach -->
                <tr>
                  <td class="border border-dark">2</td>
                  <td class="border border-dark">Autowaesche Pkw Einfach</td>
                  <td class="border border-dark">
                    <ul>
                      <li>Hochdruck-Vorwäsche</li>
                      <li>Aktivschaum</li>
                      <li>Waschen</li>
                      <li>Felgenwäsche</li>
                      <li>Trocknen</li>
                    </ul>
                  </td>
                  <td class="border border-dark">10000009</td>
                  <td class="border border-dark">variable</td>
                  <td class="border border-dark">Date of purchase</td>
                  <td class="border border-dark">13 Weeks</td>
                  <td class="border border-dark">false</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">8.5</td>
                  <td class="border border-dark"></td>
                </tr>

                <!-- Lkw-Waesche -->
                <tr>
                  <td class="border border-dark">3</td>
                  <td class="border border-dark">Lkw-Waesche</td>
                  <td class="border border-dark">
                    <ul>
                      <li>LKW-Hochdruck-Vorwäsche</li>
                      <li>Aktivschaum</li>
                      <li>LKW-Waschen</li>
                      <li>Felgenwäsche</li>
                      <li>LKW-Trocknen</li>
                    </ul>
                  </td>
                  <td class="border border-dark">10000010</td>
                  <td class="border border-dark">variable</td>
                  <td class="border border-dark">Date of purchase</td>
                  <td class="border border-dark">13 Weeks</td>
                  <td class="border border-dark">false</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">35.5</td>
                  <td class="border border-dark"></td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->


      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CarWashSimulatedStations",
  keywords: [
    'Simulated Car Wash Stations', 'Car Wash Simulation', 'Car Wash Testing', 'Simulated Wash Stations',
    'API Testing', 'Test Scenarios', 'Wash Station Simulation', 'Car Wash API', 'Mock Car Wash Stations',
    'Virtual Car Wash', 'Wash Station Behavior', 'Wash Station Errors', 'API Simulation', 'Test Environment',
    'Test Stations', 'Testing Tools', 'API Debugging', 'Simulation Setup', 'Simulated Environments',
    'Car Wash Scenarios', 'Test Wash Stations', 'Wash Station Performance', 'Simulated Car Wash',
    'Behavior Testing', 'Test Cases', 'API Validation', 'Station Configuration', 'Wash Configuration',
    'API Testing Guide', 'Test Data', 'Test Setup', 'Mock API', 'Simulated Stations', 'Wash Error Handling',
    'Wash Testing Examples', 'API Test Scripts', 'Wash Simulation', 'Wash Station Interaction', 'Station Simulation',
    'Test Washes', 'Test Simulation', 'API Integration Testing', 'Wash Testing Tools', 'Scenario Testing',
    'API Test Environment', 'Car Wash Simulation', 'Testing Scenarios', 'API Simulation Guide', 'Virtual Wash Setup',
    'Car Wash API', 'Simulated Station Guide', 'Testing Best Practices', 'API Test Scenarios', 'Simulation Tools',
    'API Test Data', 'API Mocking', 'Wash Process Scenarios', 'Simulated Test Environment', 'Station and Wash Testing',
    'Test Execution', 'Wash Test Examples', 'API Test Cases', 'Testing Framework', 'API Mock Setup'
  ]
}
</script>